import React, { useState, useEffect } from "react";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
   AppBar,
   Toolbar,
   Typography,
   Button,
   Box,
   TextField,
   Grid,
   Hidden,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { Logout } from "@mui/icons-material";
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import UserPetitionsList from "../Commons/userPetitionsList";
import LoadingChillax from "../Commons/loadingChillax";
import { CircularProgress } from "@mui/material";
import ErrorPopupAlert from "../Commons/errorPopupAlert";

function Home() {
   const navigate = useNavigate();
   const [points, setPoints] = useState(0);
   const [user, setUser] = useState(null);
   const [petitionCreated, setPetitionCreated] = useState(false);

   const app = initializeApp({
      apiKey: "AIzaSyAUGT4BFroUtLaX1DwmKRYBimlGIZOzqpQ",
      authDomain: "chillax-97907.firebaseapp.com",
      projectId: "chillax-97907",
      storageBucket: "chillax-97907.appspot.com",
      messagingSenderId: "740255498112",
      appId: "1:740255498112:web:a2ba0f2ac1c7d16f4b4b65",
   });

   const handleLogout = () => {
      const auth = getAuth();
      signOut(auth)
         .then(() => {
            // Sign-out successful.
            navigate("/");
            console.log("Signed out successfully");
         })
         .catch((error) => {
            // An error happened.
         });
   };

   useEffect(() => {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
         if (user) {
            const uid = user.uid;
            setUser(user);
         } else {
            navigate("/login");
         }
      });
   }, []);

   const handlePetitionCreated = () => {
      setPetitionCreated(!petitionCreated);
   };

   return (
      <div>
         <AppBar position="static">
            <Toolbar>
               <Grid container alignItems="center">
                  <Grid item xs />
                  <Grid item xs>
                     <Typography variant="h6" component="div" align="center">
                        Chillax Lounge
                     </Typography>
                  </Grid>
                  <Grid
                     item
                     xs
                     style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                     <Button
                        color="inherit"
                        onClick={handleLogout}
                        startIcon={<LogoutIcon />}
                     >
                        <Hidden implementation="css" smDown>
                           Cerrar Sesión
                        </Hidden>
                     </Button>
                  </Grid>
               </Grid>
            </Toolbar>
         </AppBar>
         <Box sx={{ m: 4 }}>
            <Typography variant="h5" component="div" textAlign={"center"}>
               Bienvenido a tu programa de puntos de Chillax Lounge
            </Typography>
            <UserPoints currentUser={user} />
            <br></br>
            <CreatePetition
               currentUser={user}
               onPetitionCreated={handlePetitionCreated}
            />
            <br></br>
            <UserPetitionsList currentUser={user} refresh={petitionCreated} />
         </Box>
      </div>
   );
}

export default Home;

function LogoutButton({ onLogout }) {
   const handleLogout = () => {
      // Call the onLogout function passed in as a prop
      onLogout();
   };

   return (
      <Button
         variant="contained"
         color="primary"
         endIcon={<Logout />}
         onClick={handleLogout}
      >
         Logout
      </Button>
   );
}

const UserPoints = ({ currentUser }) => {
   const [points, setPoints] = useState(null);
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState(null);

   useEffect(() => {
      const fetchUserPoints = async () => {
         try {
            setLoading(true);
            const functions = getFunctions();
            const getUserPoints = httpsCallable(functions, "getUserPoints");
            const result = await getUserPoints();
            setPoints(result.data.points);
         } catch (error) {
            console.error("Error fetching user points:", error);
            setError(error);
         } finally {
            setLoading(false);
         }
      };

      if (currentUser) {
         fetchUserPoints();
      }
   }, [currentUser]);

   if (loading) {
      return (
         <Box textAlign="center" sx={{ m: 1, mt: 4 }}>
            <CircularProgress sx={{ m: 1 }} />
         </Box>
      );
   }

   if (error) {
      return (
         <Box textAlign="center" sx={{ m: 1 }}>
            <Typography variant="body1" color="error.main">
               Ha ocurrido un error obteniendo tus puntos
            </Typography>
         </Box>
      );
   }

   return (
      <Grid container direction="column" alignItems="center" sx={{ mt: 4 }}>
         {points !== null && (
            <>
               <Typography variant="body1">Tienes</Typography>
               <Typography variant="h4">{points}</Typography>
               <Typography variant="body1">puntos</Typography>
            </>
         )}
      </Grid>
   );
};

const CreatePetition = ({ currentUser, onPetitionCreated }) => {
   const [ticket, setTicket] = useState("");
   const [amount, setAmount] = useState("");
   const [date, setDate] = useState("");
   const [loading, setLoading] = useState(false);
   const [ticketError, setTicketError] = useState(false);
   const [amountError, setAmountError] = useState(false);
   const [dateError, setDateError] = useState(false);
   const [error, setError] = useState(null);
   const [isFormValid, setIsFormValid] = useState(false);

   const handleSubmit = async (event) => {
      if (event) {
         event.preventDefault();
      }
      setLoading(true);

      if (!currentUser) {
         console.log("You must be authenticated to create a petition.");
         setLoading(false);
         setError("Ha habido un problema creando la petición.");
         return;
      }

      try {
         const functions = getFunctions();
         const createPetition = httpsCallable(functions, "createPetition");
         const result = await createPetition({ ticket, amount, date });
         console.log(`Petition created with ID: ${result.data.petitionId}`);
         setTicket("");
         setAmount("");
         setDate("");
         setLoading(false);
         setIsFormValid(false);
         onPetitionCreated();
      } catch (error) {
         console.error("Error creating petition:", error);
         console.log("An error occurred while creating the petition.");
         setLoading(false);
         setError(error);
      }
   };

   const handleTicketChange = (event) => {
      const { value } = event.target;
      setTicket(value);

      if (value.length > 9) {
         setTicketError(true);
      } else {
         setTicketError(false);
      }
      checkFormValid(value, amount, dateError);
   };

   const handleAmountChange = (event) => {
      const { value } = event.target;
      setAmount(value);

      if (/^\d+(?:\,\d{0,2})?$/.test(value)) {
         setAmountError(false);
      } else {
         setAmountError(true);
      }
      checkFormValid(ticket, value, dateError);
   };

   const handleDateChange = (event) => {
      const { value } = event.target;
      const today = new Date();
      const inputDate = new Date(value);
      setDate(value);
      let dateError = false;

      if (inputDate > today) {
         dateError = true;
      }
      setDateError(dateError);
      checkFormValid(ticket, amount, dateError);
   };

   const checkFormValid = (ticket, amount, dateError) => {
      console.log(ticket, amount, dateError);
      console.log(
         ticket.length <= 9 && /^\d+(?:\,\d{0,2})?$/.test(amount) && !dateError
      );
      setIsFormValid(
         ticket.length <= 9 && /^\d+(?:\,\d{0,2})?$/.test(amount) && !dateError
      );
   };

   const handleClose = () => {
      setError(null);
   };

   const handleRetry = () => {
      setError(null);
      handleSubmit();
   };

   if (error)
      return (
         <ErrorPopupAlert
            message={"No se ha podido crear la petición."}
            onClose={handleClose}
            onRetry={handleRetry}
         />
      );

   return (
      <Box textAlign="center" sx={{ mt: 2 }}>
         <Typography variant="h6">Sube tu ticket</Typography>
         <Box
            component="form"
            onSubmit={handleSubmit}
            display="flex"
            flexDirection="column"
            sx={{ mt: 2 }}
         >
            <TextField
               label="Ticket"
               value={ticket}
               onChange={handleTicketChange}
               fullWidth
               required
               error={ticketError}
               helperText={
                  ticketError
                     ? "El ticket tiene que tener 9 carácteres o menos"
                     : ""
               }
               sx={{ mb: 1 }}
            />
            <TextField
               label="Importe"
               value={amount}
               onChange={handleAmountChange}
               fullWidth
               required
               error={amountError}
               helperText={
                  amountError
                     ? "El importe debe ser un numero decimal con máximo dos decimales. Usa coma (,) para separar los decimales."
                     : ""
               }
               InputProps={{
                  inputMode: "decimal",
                  endAdornment: "€",
                  inputProps: { step: "0.01" },
               }}
               sx={{ mb: 1 }}
            />
            <TextField
               label="Fecha del ticket"
               value={date}
               onChange={handleDateChange}
               type="date"
               fullWidth
               required
               error={dateError}
               helperText={
                  dateError ? "La fecha no puede ser posterior a hoy" : ""
               }
               InputLabelProps={{
                  shrink: true,
               }}
               sx={{ mb: 1 }}
            />
            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
               }}
            >
               <Button
                  type="submit"
                  variant="contained"
                  disabled={loading || !isFormValid}
                  sx={{ mr: 1 }}
               >
                  {loading ? <CircularProgress size={24} /> : "Enviar"}
               </Button>
            </Box>
         </Box>
      </Box>
   );
};
