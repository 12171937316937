import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '@fontsource/montserrat';
import Load from './load/load';
import Signup from './signup/signup';
import Login from './login/login';
import Home from './home/home';
import Admin from './admin/admin';

const theme = createTheme({
  palette: {
    primary: {
      main: '#001523',
    },
    secondary: {
      main: '#00b0ea',
    },
    color2: {
      main: '#003d58',
    },
    color3: {
      main: '#00719b',
    },
    color4: {
      main: '#00b0ea',
    },
    color5: {
      main: '#00fcff',
    },
    color6: {
      main: '#ffffff',
    },
    color7: {
      main: '#000005',
    },
  },
  typography: {
    fontFamily: 'Montserrat, Arial, sans-serif',
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div>
          <section>
            <Routes>
              <Route path="/" element={<Load />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/home" element={<Home />} />
              <Route path="/admin" element={<Admin />} />
            </Routes>
          </section>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;