import React, { useState, useEffect } from "react";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Button, Box, TextField, Typography, AppBar, Toolbar, Hidden, Grid } from "@mui/material";
import { Logout } from "@mui/icons-material";
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import LogoutIcon from "@mui/icons-material/Logout";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import LoadingChillax from "../Commons/loadingChillax";

function Admin() {
   const navigate = useNavigate();
   const [user, setUser] = useState(null);
   const [petitions, setPetitions] = useState([]);
   const [lastPetitionId, setLastPetitionId] = useState(null);
   const [prevPageStartIds, setPrevPageStartIds] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [startAfter, setStartAfter] = useState(null);

   const app = initializeApp({
      apiKey: "AIzaSyAUGT4BFroUtLaX1DwmKRYBimlGIZOzqpQ",
      authDomain: "chillax-97907.firebaseapp.com",
      projectId: "chillax-97907",
      storageBucket: "chillax-97907.appspot.com",
      messagingSenderId: "740255498112",
      appId: "1:740255498112:web:a2ba0f2ac1c7d16f4b4b65",
   });

   const handleLogout = () => {
      const auth = getAuth();
      signOut(auth)
         .then(() => {
            // Sign-out successful.
            navigate("/");
            console.log("Signed out successfully");
         })
         .catch((error) => {
            // An error happened.
         });
   };

   useEffect(() => {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
         if (user) {
            const uid = user.uid;
            setUser(user);
            fetchPetitions();
         } else {
            navigate("/login");
         }
      });
   }, []);

   const fetchPetitions = async (startAfter) => {
      try {
         setIsLoading(true);
         const functions = getFunctions();
         const getPetitionsPage = httpsCallable(functions, "getPetitionsPage");
         const result = await getPetitionsPage({ startAfter });
         setPetitions(result.data.petitions);

         if (result.data.petitions.length > 0) {
            setLastPetitionId(
               result.data.petitions[result.data.petitions.length - 1].id
            );
         }
         setIsLoading(false);
      } catch (error) {
         console.error("Error fetching petitions:", error);
         setIsLoading(false);
      }
   };

   const handleNextPage = () => {
      const lastDocId = petitions[petitions.length - 1]?.id || null;
      setStartAfter(lastDocId);
      fetchPetitions(lastDocId);
   };

   const handlePrevPage = () => {
      if (petitions.length > 0) {
         const firstDocId = petitions[0].id;
         const firstDocIndex = petitions.findIndex(
            (petition) => petition.id === firstDocId
         );
         const prevStartAfter = petitions[firstDocIndex - 1]?.id || null;
         setStartAfter(prevStartAfter);
         fetchPetitions(prevStartAfter);
      }
   };

   const handleApprove = async (petition) => {
      console.log("Approve petition:", petition.petitionId);

      try {
         setIsLoading(true);
         const functions = getFunctions();
         const approvePetition = httpsCallable(functions, "approvePetition");
         const result = await approvePetition({ petition });
         console.log("Petition approved:", result);
         fetchPetitions();
         // Reload or update the petitions list as needed
      } catch (error) {
         console.error("Error approving petition:", error);
         setIsLoading(false);
         alert("An error occurred while approving the petition.");
      }
   };

   const handleCancel = async (petition) => {
      console.log("Cancel petition:", petition.petitionId);

      try {
         setIsLoading(true);
         const functions = getFunctions();
         const cancelPetition = httpsCallable(functions, "cancelPetition");
         const result = await cancelPetition({ petition });
         console.log("Petition canceled:", result);
         fetchPetitions();
         // Reload or update the petitions list as needed
      } catch (error) {
         console.error("Error canceling petition:", error);
         setIsLoading(false);
         alert("An error occurred while canceling the petition.");
      }
   };

   return (
      <div style={{ position: "relative", height: "100vh" }}>
         <AppBar position="static">
            <Toolbar>
               <Grid container alignItems="center">
                  <Grid item xs />
                  <Grid item xs>
                     <Typography variant="h6" component="div" align="center">
                        Admin
                     </Typography>
                  </Grid>
                  <Grid
                     item
                     xs
                     style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                     <Button
                        color="inherit"
                        onClick={handleLogout}
                        startIcon={<LogoutIcon />}
                     >
                        <Hidden implementation="css" smDown>
                           Cerrar Sesión
                        </Hidden>
                     </Button>
                  </Grid>
               </Grid>
            </Toolbar>
         </AppBar>
         {isLoading ? (
            <LoadingChillax />
         ) : (
            <Box textAlign="center" sx={{ mt: 2 }}>
               <Typography variant="h6">Historial de peticiones</Typography>
               <List>
                  {petitions.map((petition) => (
                     <ListItem key={petition.id}>
                        <ListItemText
                           primary={`Ticket: ${petition.ticket} - Puntos: ${
                              petition.amount * 100
                           }`}
                           secondary={`email: ${petition.userEmail}, Estado: ${
                              petition.pending ? "Pendiente" : "Completado"
                           }, Resultado: ${
                              petition.valid ? "Válido" : "No Válido"
                           }`}
                        />
                        {petition.pending && (
                           <ListItemSecondaryAction>
                              <IconButton
                                 edge="end"
                                 aria-label="approve"
                                 onClick={() => handleApprove(petition)}
                              >
                                 <CheckIcon />
                              </IconButton>
                              <IconButton
                                 edge="end"
                                 aria-label="cancel"
                                 onClick={() => handleCancel(petition)}
                              >
                                 <CloseIcon />
                              </IconButton>
                           </ListItemSecondaryAction>
                        )}
                     </ListItem>
                  ))}
               </List>
               <br></br>
               <Box display="flex" justifyContent="center" my={2}>
                  <Button onClick={handlePrevPage} disabled={!petitions.length || !startAfter}>
                     Inicio
                  </Button>
                  <Button onClick={handleNextPage} disabled={petitions.length < 10}>Siguiente</Button>
               </Box>
            </Box>
         )}
      </div>
   );
}

export default Admin;

function LogoutButton({ onLogout }) {
   const handleLogout = () => {
      // Call the onLogout function passed in as a prop
      onLogout();
   };

   return (
      <Button
         variant="contained"
         color="primary"
         endIcon={<Logout />}
         onClick={handleLogout}
      >
         Logout
      </Button>
   );
}
