import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
   Avatar,
   Button,
   CssBaseline,
   TextField,
   Link,
   Grid,
   Box,
   Typography,
   Container,
   CircularProgress,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import ErrorPopupAlert from "../Commons/errorPopupAlert";

export default function Signup() {
   const navigate = useNavigate();

   const app = initializeApp({
      apiKey: "AIzaSyAUGT4BFroUtLaX1DwmKRYBimlGIZOzqpQ",
      authDomain: "chillax-97907.firebaseapp.com",
      projectId: "chillax-97907",
      storageBucket: "chillax-97907.appspot.com",
      messagingSenderId: "740255498112",
      appId: "1:740255498112:web:a2ba0f2ac1c7d16f4b4b65",
   });

   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [passwordConfirmation, setPasswordConfirmation] = useState("");
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);

   const handleEmailChange = (event) => {
      setEmail(event.target.value);
   };

   const handlePasswordChange = (event) => {
      setPassword(event.target.value);
   };
   
   const handlePasswordConfirmationChange = (event) => {
      setPasswordConfirmation(event.target.value);
   };

   const handleCreateUser = () => {
      const functions = getFunctions();
      const createUser = httpsCallable(functions, "createUser");

      createUser()
         .then((result) => {
            console.log(result.data);
            setLoading(false);
            navigate("/login");
         })
         .catch((error) => {
            console.error(error);
            setLoading(false);
            setError("No se ha podido crear tu usuario");
         });
   };

   const handleSignup = async (event) => {
      event.preventDefault();

      if (password !== passwordConfirmation) {
         setError("Las contraseñas no coinciden.");
         return;
       }

      setLoading(true);
      try {
         const auth = getAuth();
         await createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
               // Signed in
               const user = userCredential.user;
               console.log(user);
               handleCreateUser();
            })
            .catch((error) => {
               const errorCode = error.code;
               const errorMessage = error.message;
               console.log(errorCode, errorMessage);
               setLoading(false);
               switch (errorCode) {
                  case "auth/email-already-in-use":
                    setError("Este correo electrónico ya está en uso.");
                    break;
                  case "auth/invalid-email":
                    setError("Correo electrónico no válido.");
                    break;
                  case "auth/weak-password":
                    setError("Contraseña débil. Debe tener al menos 6 caracteres.");
                    break;
                  default:
                    setError("Ha ocurrido un error al crear la cuenta.");
                    break;
                }
            });
      } catch (error) {
         console.error(error);
         setLoading(false);
         setError("Ha ocurrido un error inesperado.");
      }
   };

   return (
      <Box
         sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
         }}
      >
         {error && (
            <ErrorPopupAlert message={error} onClose={() => setError(null)} />
         )}
         <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className="paper">
               <Typography
                  component="h1"
                  variant="h5"
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                  }}
               >
                  <LockOutlinedIcon sx={{ mr: 1 }} />
                  Regístrate
               </Typography>
               <Box component="form" className="form" onSubmit={handleSignup}>
                  <TextField
                     variant="outlined"
                     margin="normal"
                     required
                     fullWidth
                     id="email"
                     label="Email"
                     name="email"
                     autoComplete="email"
                     autoFocus
                     value={email}
                     onChange={handleEmailChange}
                  />
                  <TextField
                     variant="outlined"
                     margin="normal"
                     required
                     fullWidth
                     name="password"
                     label="Contraseña"
                     type="password"
                     id="password"
                     autoComplete="current-password"
                     value={password}
                     onChange={handlePasswordChange}
                  />
                  <TextField
                     variant="outlined"
                     margin="normal"
                     required
                     fullWidth
                     name="passwordConfirmation"
                     label="Confirmar contraseña"
                     type="password"
                     id="passwordConfirmation"
                     autoComplete="new-password"
                     value={passwordConfirmation}
                     onChange={handlePasswordConfirmationChange}
                  />
                  <Button
                     type="submit"
                     fullWidth
                     variant="contained"
                     color="primary"
                     className="submit"
                     disabled={loading}
                  >
                     {loading ? <CircularProgress size={24} /> : "Regístrate"}
                  </Button>
                  <Grid container>
                     <Grid item>
                        <Link href="login" variant="body2" underline="hover">
                           {"¿Ya tienes una cuenta? Inicia Sesión"}
                        </Link>
                     </Grid>
                  </Grid>
               </Box>
            </div>
         </Container>
      </Box>
   );
}
