import { Box, Button, Typography, styled } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

const StyledBox = styled(Box)({
  backgroundColor: '#f44336',
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px',
});

const StyledErrorOutline = styled(ErrorOutline)({
  marginRight: '16px',
});

const ErrorAlert = ({ message, onRetry, onClose }) => {
  return (
    <StyledBox>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <StyledErrorOutline fontSize="large" />
        <Typography variant="body1">{message}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {onRetry && (
          <Button variant="outlined" color="inherit" onClick={onRetry}>
            Reintentar
          </Button>
        )}
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Cerrar
        </Button>
      </Box>
    </StyledBox>
  );
};

export default ErrorAlert;