import React, { useState, useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
   Box,
   Typography,
   Button,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { Timestamp } from "firebase/firestore";
import ErrorAlert from "./errorAlert";
import ErrorPopupAlert from "./errorPopupAlert";
import LoadingChillax from "./loadingChillax";

const UserPetitionList = ({ currentUser, refresh }) => {
   const [petitions, setPetitions] = useState([]);
   const [startAfter, setStartAfter] = useState(null);
   const [error, setError] = useState(null);
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      const fetchPetitions = async () => {
        setIsLoading(true);
         try {
            if (!currentUser) {
               console.error("You must be authenticated to create a petition.");
               setIsLoading(false);
               setError(error);
               return;
            }

            const uid = currentUser.uid;

            const functions = getFunctions();
            const getPetitionsPage = httpsCallable(
               functions,
               "getPetitionsPage"
            );
            const result = await getPetitionsPage({ startAfter, uid });
            setPetitions(result.data.petitions);
            setIsLoading(false);
         } catch (error) {
            console.error("Error fetching petitions:", error);
            setIsLoading(false);
            setError(error);
         }
      };

      fetchPetitions();
   }, [startAfter, currentUser, refresh]);

   const handlePrevPage = () => {
      if (petitions.length > 0) {
         const firstDocId = petitions[0].id;
         const firstDocIndex = petitions.findIndex(
            (petition) => petition.id === firstDocId
         );
         const prevStartAfter = petitions[firstDocIndex - 1]?.id || null;
         setStartAfter(prevStartAfter);
      }
   };

   const handleNextPage = () => {
      const lastDocId = petitions[petitions.length - 1]?.id || null;
      setStartAfter(lastDocId);
   };

   const handleClose = () => {
      setError(null);
   };

   const handleRetry = () => {
      setError(null);
      setStartAfter(null);
   };

   const formatDate = (timestamp) => {
      console.log("Timestamp:", timestamp);

      let date;

      if (timestamp instanceof Timestamp) {
         date = timestamp.toDate();
      } else if (timestamp._seconds && timestamp._nanoseconds) {
         date = new Date(
            timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
         );
      } else {
         return "Invalid timestamp";
      }

      return `${date.getDate().toString().padStart(2, "0")}/${(
         date.getMonth() + 1
      )
         .toString()
         .padStart(2, "0")}/${date.getFullYear()}`;
   };

   return (
      <Box textAlign="center" sx={{ mt: 2 }}>
         <Typography variant="h6">
            Historial de Tickets
         </Typography>
      <Box>
         {isLoading ? (
            <LoadingChillax />
         ) : (
            <>
               <TableContainer>
                  <Table>
                     <TableHead>
                        <TableRow>
                           <TableCell>Ticket</TableCell>
                           <TableCell>Fecha</TableCell>
                           <TableCell>Estado</TableCell>
                           <TableCell>Resultado</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {petitions.map((petition) => (
                           <TableRow key={petition.id}>
                              <TableCell>{petition.ticket}</TableCell>
                              <TableCell>
                                 {formatDate(petition.created)}
                              </TableCell>
                              <TableCell>
                                 {petition.pending
                                    ? "En revisión"
                                    : "Completado"}
                              </TableCell>
                              <TableCell>
                                 {!petition.pending &&
                                    (petition.valid ? (
                                       <Typography
                                          sx={{ color: green[500] }}
                                       >{`+${
                                          petition.amount * 100
                                       }`}</Typography>
                                    ) : (
                                       "Ticket no válido"
                                    ))}
                              </TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                  </Table>
               </TableContainer>
               <Box display="flex" justifyContent="center" marginTop={2} marginBottom={2}>
                  <Button onClick={handlePrevPage} disabled={!petitions.length || !startAfter}>
                     Inicio
                  </Button>
                  <Button onClick={handleNextPage} disabled={petitions.length < 10}>Siguiente</Button>
               </Box>
            </>
         )}
         {error && (
            <ErrorPopupAlert
               message={"No hemos podido obtener la lista de peticiones"}
               onClose={handleClose}
            />
         )}
      </Box>
      </Box>
   );
};

export default UserPetitionList;
