import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
   Avatar,
   Button,
   CssBaseline,
   TextField,
   FormControlLabel,
   Checkbox,
   Link,
   Grid,
   Box,
   Typography,
   Container,
   CircularProgress,
} from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
   getAuth,
   signInWithEmailAndPassword,
   GoogleAuthProvider,
   signInWithPopup,
   getAdditionalUserInfo,
   OAuthProvider,
   sendPasswordResetEmail
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import ErrorPopupAlert from "../Commons/errorPopupAlert";

export default function Login() {
   const navigate = useNavigate();

   const app = initializeApp({
      apiKey: "AIzaSyAUGT4BFroUtLaX1DwmKRYBimlGIZOzqpQ",
      authDomain: "chillax-97907.firebaseapp.com",
      projectId: "chillax-97907",
      storageBucket: "chillax-97907.appspot.com",
      messagingSenderId: "740255498112",
      appId: "1:740255498112:web:a2ba0f2ac1c7d16f4b4b65",
   });

   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);

   const [openLost, setOpenLost] = useState(false);
   const [emailLost, setEmailLost] = useState("");
   const [successLost, setSuccessLost] = useState(false);

   const handleGoogleLogin = async () => {
      const provider = new GoogleAuthProvider();
      await handleSocialLogin(provider);
   };

   const handleAppleLogin = async () => {
      const provider = new OAuthProvider("apple.com");
      await handleSocialLogin(provider);
   };

   const handleSocialLogin = async (provider) => {
      try {
         setLoading(true);
         const auth = getAuth();
         const result = await signInWithPopup(auth, provider);
         const isNewUser = getAdditionalUserInfo(result).isNewUser;
         if (isNewUser) {
            const functions = getFunctions();
            const createUser = httpsCallable(functions, "createUser");
            await createUser();
         }
         checkAdminStatus();
      } catch (error) {
         console.error("Error signing in with social account:", error);
         setLoading(false);
         setError("No se ha podido iniciar sesión");
      }
   };

   const checkAdminStatus = async () => {
      try {
         const functions = getFunctions();
         const isUserAdmin = httpsCallable(functions, "isUserAdmin");
         const result = await isUserAdmin();
         const isAdmin = result.data.isAdmin;

         if (isAdmin) {
            navigate("/admin");
         } else {
            navigate("/home");
         }
      } catch (error) {
         console.error("Error checking admin status:", error);
         navigate("/home");
      } finally {
         setLoading(false);
      }
   };

   const handleEmailChange = (event) => {
      setEmail(event.target.value);
   };

   const handlePasswordChange = (event) => {
      setPassword(event.target.value);
   };

   const handleLogin = async (event) => {
      event.preventDefault();
      setLoading(true);
      try {
         const auth = getAuth();
         signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
               // Signed in
               const user = userCredential.user;
               // Check if the user is an admin
               checkAdminStatus();
               console.log(user);
            })
            .catch((error) => {
               const errorCode = error.code;
               const errorMessage = error.message;
               console.log(errorCode, errorMessage);
               setLoading(false);
               switch (errorCode) {
                  case "auth/invalid-email":
                     setError(
                        "La dirección de correo electrónico no es válida."
                     );
                     break;
                  case "auth/user-disabled":
                     setError("La cuenta ha sido deshabilitada.");
                     break;
                  case "auth/user-not-found":
                     setError(
                        "La dirección de correo electrónico o la contraseña son incorrectas."
                     );
                     break;
                  case "auth/wrong-password":
                     setError(
                        "La dirección de correo electrónico o la contraseña son incorrectas."
                     );
                     break;
                  default:
                     setError("Ha ocurrido un error inesperado.");
               }
            });
      } catch (error) {
         console.error(error);
         setLoading(false);
         setError("Ha ocurrido un error inesperado.");
      }
   };

   const handleOpenLost = () => {
      setOpenLost(true);
   };

   const handleCloseLost = () => {
      setOpenLost(false);
      setEmailLost("");
      setError(null);
      setSuccessLost(false);
   };

   const handleSubmitLost = async (event) => {
      event.preventDefault();
      try {
         console.log("started");
         const auth = getAuth();
         console.log("auth");
         console.log(auth);
         await sendPasswordResetEmail(auth, emailLost);
         setSuccessLost(true);
         setError(null);
      } catch (error) {
         console.error(error);
         setError(
            "Ha ocurrido un error al enviar el correo de reestablecimiento."
         );
         setSuccessLost(false);
      }
   };

   return (
      <Box
         sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
         }}
      >
         {error && (
            <ErrorPopupAlert message={error} onClose={() => setError(null)} />
         )}
         <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className="paper">
               <Typography
                  component="h1"
                  variant="h5"
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                  }}
               >
                  <LockOutlinedIcon sx={{ mr: 1 }} />
                  Inicia sesión
               </Typography>
               <Box component="form" className="form" onSubmit={handleLogin}>
                  <TextField
                     variant="outlined"
                     margin="normal"
                     required
                     fullWidth
                     id="email"
                     label="Email"
                     name="email"
                     autoComplete="email"
                     autoFocus
                     value={email}
                     onChange={handleEmailChange}
                  />
                  <TextField
                     variant="outlined"
                     margin="normal"
                     required
                     fullWidth
                     name="password"
                     label="Contraseña"
                     type="password"
                     id="password"
                     autoComplete="current-password"
                     value={password}
                     onChange={handlePasswordChange}
                  />
                  <Button
                     type="submit"
                     fullWidth
                     variant="contained"
                     color="primary"
                     className="submit"
                     disabled={loading}
                  >
                     {loading ? (
                        <CircularProgress size={24} />
                     ) : (
                        "Inicia sesión"
                     )}
                  </Button>
                  <Grid container>
                     <Grid item xs>
                        <Link href="signup" variant="body2" underline="hover">
                           {"Crear una cuenta"}
                        </Link>
                     </Grid>
                     <Grid item>
                        <Link
                           variant="body2"
                           underline="hover"
                           onClick={handleOpenLost}
                        >
                           ¿Has olvidado tu contraseña?
                        </Link>
                     </Grid>
                  </Grid>
                  <br></br>
                  <Dialog open={openLost} onClose={handleCloseLost}>
                     <DialogTitle>Restablecer contraseña</DialogTitle>
                     <DialogContent>
                        <Box
                           component="form"
                           onSubmit={handleSubmitLost}
                           display="flex"
                           flexDirection="column"
                        >
                           <TextField
                              label="Email"
                              value={emailLost}
                              onChange={(event) => setEmailLost(event.target.value)}
                              fullWidth
                              required
                              sx={{ mb: 1 }}
                           />
                           {successLost && (
                              <Box color="success.main">
                                 Se ha enviado un correo de reestablecimiento a{" "}
                                 {emailLost}
                              </Box>
                           )}
                        </Box>
                     </DialogContent>
                     <DialogActions>
                        <Button onClick={handleCloseLost}>Cancelar</Button>
                        <Button
                           type="submit"
                           variant="contained"
                           color="primary"
                           onClick={handleSubmitLost}
                        >
                           Enviar correo de reestablecimiento
                        </Button>
                     </DialogActions>
                  </Dialog>
                  <Button
                     sx={{ mv: 2 }}
                     fullWidth
                     variant="outlined"
                     color="primary"
                     className="googleSignIn"
                     onClick={handleGoogleLogin}
                     disabled={loading}
                  >
                     {loading ? (
                        <CircularProgress size={24} />
                     ) : (
                        "Inicia Sesión con Google"
                     )}
                  </Button>
               </Box>
            </div>
         </Container>
      </Box>
   );
}
