import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, styled } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

const StyledErrorOutline = styled(ErrorOutline)({
  color: '#f44336',
  marginRight: '16px',
});

const ErrorPopupAlert = ({ message, onRetry, onClose }) => {
  return (
    <Dialog open={true} onClose={onClose} maxWidth="xs" sx={{ minWidth: '50vw' }}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', padding: '16px 24px' }}>
        <StyledErrorOutline fontSize="large" />
        <Typography variant="h6">Error</Typography>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', alignItems: 'center', minHeight: '120px' }}>
        <Typography variant="body1">{message}</Typography>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', padding: '16px 24px' }}>
        {onRetry && (
          <Button variant="outlined" color="primary" onClick={onRetry} sx={{ mr: 1 }}>
            Reintentar
          </Button>
        )}
        <Button variant="outlined" color="primary" onClick={onClose}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorPopupAlert;
