import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box, display } from "@mui/system";
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from 'firebase/functions';

function Load() {
   const navigate = useNavigate();

   const app = initializeApp({
      apiKey: "AIzaSyAUGT4BFroUtLaX1DwmKRYBimlGIZOzqpQ",
      authDomain: "chillax-97907.firebaseapp.com",
      projectId: "chillax-97907",
      storageBucket: "chillax-97907.appspot.com",
      messagingSenderId: "740255498112",
      appId: "1:740255498112:web:a2ba0f2ac1c7d16f4b4b65",
   });

   useEffect(() => {
      const timer = setTimeout(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            // User is signed in
            const uid = user.uid;
    
            console.log("uid", uid);
    
            // Check if the user is an admin
            try {
              const functions = getFunctions();
              const isUserAdmin = httpsCallable(functions, 'isUserAdmin');
              const result = await isUserAdmin();
              const isAdmin = result.data.isAdmin;
    
              if (isAdmin) {
                navigate("/admin");
              } else {
                navigate("/home");
              }
            } catch (error) {
              console.error('Error checking admin status:', error);
              navigate("/home");
            }
          } else {
            // User is signed out
            navigate("/login");
          }
        });
      }, 2000);
    
      return () => clearTimeout(timer);
    }, []);

   return (
      <Box
         sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
         }}
      >
         <CircularProgress />
      </Box>
   );
}

export default Load;
